.formio-form:not(.formio-read-only) {
  // Dropdown arrow caret
  .choices[data-type*="select-one"] {
    &.is-open::after,
    &::after {
      width: 10px;
      height: 10px;
      border-left: 0;
      border-bottom: 0;
      border-right: 0.1em solid $dark-fiordland;
      border-top: 0.1em solid $dark-fiordland;
      top: calc(50% - 5px);
    }
    // Down arrow caret
    &::after {
      transform: rotate(135deg);
    }
    // Up arrow caret
    &.is-open::after {
      transform: rotate(-45deg);
      top: calc(50% + 5px);
    }
  }

  .choices {
    .form-control.dropdown {
      border-radius: 0;
      background: $light-mid;
      border-top: 0;
      border-left: 0;
      border-right: 0;
      border-bottom: 1px solid $dark-neutral2;
      transition: box-shadow 0.3s ease, background 0.3s ease;

      &:focus,
      &:active {
        background: $light-taro;
        box-shadow: 0 0 0 2px $primary;
        border-bottom: 1px solid transparent;
      }

      &:hover {
        background: $light-taro;
        box-shadow: 0 0 0 2px $primary;
        border-bottom: 1px solid transparent;
        cursor: pointer;
      }

      &.is-invalid {
        border-bottom: 1px solid transparent;
      }
    }

    .form-control.dropdown .choices__input {
      background-color: unset !important;
    }

    &__list.choices__list--single {
      .choices__item.choices__item--selectable {
        .choices__button {
          // outline: 2px solid $primary;
          outline: none;
          box-shadow: none;

          &:focus {
            box-shadow: 0 0 0 2px $primary;
          }
        }
      }
    }

    &__list.choices__list--dropdown {
      padding: 5px 5px 0;
      border: 2px solid $primary;
      border-radius: 4px;

      .choices__list {
        margin: 0 -5px;
      }

      .choices__input {
        margin-bottom: 5px;
      }

      .choices__item {
        padding: 12px 16px;
        // Divider
        &::before {
          content: "";
          background: $light-taro;
          position: absolute;
          bottom: 0;
          left: 6.5%;
          height: 1px;
          width: 87%;
        }
      }

      .choices__item.choices__item--selectable.is-highlighted {
        background-color: $light-taro;
      }
    }
  }
}
