.formio-form:not(.formio-read-only) {
  .input-group {
    border-bottom: 1px solid $dark-neutral2;
    background: $light-day;
    transition: none;

    // Override border, box shadow, and background on input element inside input group
    input {
      border: 0;
      box-shadow: none;
      background: transparent;
    }

    input.is-invalid {
      box-shadow: none;
    }

    &:hover {
      background: $light-taro;
      box-shadow: 0 0 0 2px inset $primary;
      border-bottom: 1px solid transparent;
    }

    &:has(input:focus),
    &:has(input:active) {
      background: $light-taro;
      box-shadow: 0 0 0 2px inset $primary;
      border-bottom: 1px solid transparent;
    }

    &:has(input:disabled) {
      background: $light-mid;
      border-bottom: 1px solid transparent;

      input,
      .input-group-text {
        color: $dark-neutral2;
      }

      &:hover {
        box-shadow: none;

        input {
          cursor: default;
        }
      }
    }

    &:has(input.is-invalid) {
      border-bottom: 1px solid transparent;
      box-shadow: 0 0 0 2px inset $danger;
      background: $light-taro;
    }

    &:has(.input-group-append:hover):has(input:not([disabled])),
    &:has(.input-group-prepend:hover):has(input:not([disabled])) {
      background: $light-taro;
    }

    .input-group-append,
    .input-group-prepend {
      .input-group-text {
        border: none;
        border-radius: 0;
        background: transparent;
      }
    }
  }

  :not(.input-group) > {
    // Workaround: set input background color when autofill
    .form-group div[ref="element"] {
      &:has(input:-webkit-autofill, textarea:-webkit-autofill) {
        background: $light-day;
      }

      &:has(input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active, textarea:-webkit-autofill:hover, textarea:-webkit-autofill:focus, textarea:-webkit-autofill:active) {
        background: $light-taro;
      }
    }

    input.form-control,
    input.choices__input:not(.input-group),
    textarea.form-control:not(.input-group) {
      border-radius: 0;
      border-top: 0;
      border-left: 0;
      border-right: 0;
      border-bottom: 1px solid $dark-neutral2;
      background: $light-day;
      transition: none;
    }

    input.form-control:hover,
    input.choices__input:hover,
    textarea.form-control:hover {
      box-shadow: 0 0 0 2px inset $primary;
      border-bottom: 1px solid transparent;
    }

    input.form-control.is-invalid,
    input.choices__input.is-invalid,
    textarea.form-control.is-invalid {
      background: $light-taro;
      box-shadow: 0 0 0 2px inset $danger;
      border: none !important;
    }

    input.form-control:hover:not(.is-invalid),
    input.choices__input:hover:not(.is-invalid),
    textarea.form-control:hover:not(.is-invalid) {
      background: $light-taro;
    }

    input.form-control:active:not(.is-invalid),
    input.form-control:focus:not(.is-invalid),
    input.choices__input:focus:not(.is-invalid),
    textarea.form-control:active:not(.is-invalid),
    textarea.form-control:focus:not(.is-invalid) {
      background: $light-taro;
      box-shadow: 0 0 0 2px inset $primary;
      border-bottom: 1px solid transparent;
    }
  }
}
