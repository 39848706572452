@import "src/components/FormIOForm/styles/foundation/variables";

legend {
  color: $dark-neutral;
  font-weight: bold;
  margin-bottom: $spacer * 4;
}

.formio-read-only legend {
  font-size: 16px;
}

.formio-form:not(.formio-read-only) {
  p {
    color: $dark-night;
  }

  fieldset hr,
  .card-body hr {
    margin-bottom: $spacer * 4;
  }

  hr {
    margin-bottom: $spacer * 8;
  }
}

p {
  color: $dark-neutral;
  line-height: 1.5;
}

h4 {
  margin: 0;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.5em;
}

.react-component p {
  line-height: inherit;
  margin-top: inherit;
  margin-bottom: inherit;
}
