@import "classes";

.formio-form:not(.formio-read-only) {
  .formio-component-checkbox:not(.formio-component-label-hidden):not(.checkbox-normal) {
    @extend %switch-checkbox;
  }

  .formio-component-checkbox.formio-component-label-hidden {
    @extend %normal-checkbox;
  }

  .formio-component-checkbox.checkbox-normal {
    @extend %normal-checkbox;
  }

  .formio-component-checkbox .form-check-label span span {
    line-height: 1.5em;
  }

  // Workaround - reduce spacing if the next element is also a checkbox
  .formio-component-checkbox:has(+.formio-component-checkbox) {
    margin-bottom: 8px;
  }
}
