@import "src/components/FormIOForm/styles/foundation/variables";

.formio-form:not(.formio-read-only) {
  .formio-component-columns {
    & > *:first-child {
      padding-left: 0;
    }

    // Workaround: the last child of Columns is div.formio-errors
    & > *:nth-last-child(2) {
      padding-right: 0;
    }

    // Workaround: max width for text field in meeting column
    &.formio-component-meetingColumns {
      @each $breakpoint, $width in $grid-breakpoints {
        .col-#{$breakpoint}-6 {
          .form-group:has(input.form-control) {
            max-width: 464px;
          }
        }
      }
    }
  }
}
