// We are using Material-UI for the main application, so we want to scope the CSS applied just for formio
.FormIOForm {
  @import "~@objective_build/formiojs/dist/formio.form.css";
  @import "src/components/FormIOForm/styles/foundation/bootstrap";
  @import "src/components/FormIOForm/styles/foundation/card";
  @import "src/components/FormIOForm/styles/foundation/custom";
  @import "src/components/FormIOForm/styles/foundation/form-check";
  @import "src/components/FormIOForm/styles/foundation/form-group";
  @import "src/components/FormIOForm/styles/foundation/form-control";
  @import "src/components/FormIOForm/styles/foundation/grid";
  @import "src/components/FormIOForm/styles/foundation/icons";
  @import "src/components/FormIOForm/styles/foundation/label";
  @import "src/components/FormIOForm/styles/foundation/typography";
  @import "src/components/FormIOForm/styles/foundation/validation";
  @import "src/components/FormIOForm/styles/foundation/input";
  @import "src/components/FormIOForm/styles/foundation/image";
  @import "src/components/FormIOForm/styles/foundation/textarea";

  @import "src/components/FormIOForm/styles/components/address/address.review";
  @import "src/components/FormIOForm/styles/components/address/address.form";
  @import "src/components/FormIOForm/styles/components/checkbox/checkbox.review";
  @import "src/components/FormIOForm/styles/components/checkbox/checkbox.form";
  @import "src/components/FormIOForm/styles/components/columns/columns.form";
  @import "src/components/FormIOForm/styles/components/component/component.review";
  @import "src/components/FormIOForm/styles/components/radio/radio.review";
  @import "src/components/FormIOForm/styles/components/radio/radio.form";
  @import "src/components/FormIOForm/styles/components/select/select.review";
  @import "src/components/FormIOForm/styles/components/select/select.form";
  @import "src/components/FormIOForm/styles/components/selectboxes/selectboxes.form";
  @import "src/components/FormIOForm/styles/components/selectboxes/selectboxes.review";
  @import "src/components/FormIOForm/styles/components/textfield/textfield.review";
  @import "src/components/FormIOForm/styles/components/textfield/textfield.form";
  @import "src/components/FormIOForm/styles/components/editgrid/editgrid.form";
  @import "src/components/FormIOForm/styles/components/datagrid/datagrid.form";
  @import "src/components/FormIOForm/styles/components/button/button.form";
}

// Flatpickr date picker ODL styling
@import "src/components/FormIOForm/styles/components/datepicker/datepicker.form";