@import "src/components/FormIOForm/styles/foundation/variables";
@import "src/components/FormIOForm/styles/foundation/mixins";
@import "radio.classes";

.formio-form:not(.formio-read-only) {
  .formio-component-radio:not(.yes-no-buttons) {
    // Layout
    .radio-option {
      margin-bottom: 1rem;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    // Hide input
    input[type="radio"] {
      position: absolute;
      display: block;
      opacity: 0;
    }

    // Option Label - Default
    input[type="radio"] + span {
      color: $dark-neutral;
      cursor: pointer;
      font-weight: 500;
    }

    // Option Label - Checked
    input[type="radio"]:checked + span {
      color: $dark-night;
    }

    // Icon - Default
    input[type="radio"] + span::before {
      @include font-icon2;

      content: "\efd7";
      display: inline-block;
      vertical-align: middle;
      border-radius: 50%;
      margin-right: 15.25px;
      box-shadow: 0 0 0 11.5px rgba($light-taro, 0);
      transition: box-shadow 0.3s ease, background 0.3s ease;
      line-height: 1.5rem;
      width: 1.5rem;
      height: 1.5rem;
      font-size: 1.5rem;
      text-align: center;
    }

    // Icon - Checked
    input[type="radio"]:checked + span::before {
      content: "\efd8";
      color: $primary;
    }

    // Icon - Focused
    input[type="radio"]:focus-visible + span::before {
      outline: 2px solid $primary;
      outline-offset: 11.5px;
    }

    // Icon - Hover
    input[type="radio"]:hover + span::before {
      box-shadow: 0 0 0 11.5px rgba($light-taro, 0.75);
      background: rgba($light-taro, 0.75);
    }
  }

  .formio-component-radio.yes-no-buttons {
    @extend %toggle-buttons;

    .form-radio {
      width: 168px;
    }
  }
}
