@import "src/components/FormIOForm/styles/foundation/variables";
@import "src/components/FormIOForm/styles/foundation/mixins";

.formio-error-wrapper {
  padding: unset !important;
  background-color: unset !important;

  label {
    color: $danger !important;
  }
}

.has-error {
  label {
    color: $danger !important;
  }
}

.form-control.is-invalid {
  box-shadow: 0 0 0 2px inset $danger;
  background-image: unset !important;
}

.formio-errors {
  margin-top: 8px !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.2;

  .error {
    color: $danger;
  }
}

.field-required::after {
  color: $danger;
}