@import "src/components/FormIOForm/styles/foundation/mixins";

.fa {
  @include font-icon;
}

.fa-times-circle-o::before {
  content: "\f15a";
}

.fa-question-circle {
  @include font-icon2;

  &::before {
    content: "\ee82";
  }
}

.fa-calendar {
  @include font-icon2;

  &::before {
    content: "\efb6";
  }
}
