@import "src/components/FormIOForm/styles/foundation/variables";

label {
  color: $dark-neutral;
  font-weight: bold;
  padding-top: 0;
}

.formio-form:not(.formio-read-only) {
  label {
    color: $dark-night;
    font-weight: 600;
  }

  .col-form-label {
    padding-bottom: 12px;
    padding-top: 0;
  }
}

label.field-required::after {
  z-index: 1;
}
