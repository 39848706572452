.formio-form:not(.formio-read-only) {
  .input-group,
  input.form-control,
  .form-control.dropdown {
    height: 44px;
  }

  .form-control.selection.dropdown {
    display: flex;
    align-items: center;
  }
}
