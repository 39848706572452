@import "src/components/FormIOForm/styles/foundation/variables";

textarea {
  caret-color: $blue;
  color: $dark-night;
  font-weight: 500 !important;

  &:disabled {
    cursor: not-allowed;
    color: $dark;
    background-color: $light-day !important;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    background-clip: text !important;
  }
}
