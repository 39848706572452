.formio-form:not(.formio-read-only) {
  .btn {
    border-radius: 2px;
    box-shadow: none !important;
    // Button - Primary
    &.btn-primary {
      background-color: $primary;

      &:hover {
        background-color: $primary-light;
      }

      &:active {
        background-color: $primary-dark;
      }

      &:focus-visible {
        outline: 1px solid $primary;
        outline-offset: 1px;
      }
    }

    // Button - Secondary
    &.btn-secondary {
      background-color: $white;
      outline: 1px solid $light-allspice;
      color: $dark-fiordland;
      border: none;

      &:hover {
        color: $primary;
        background-color: $light-taro;
        outline: 1px solid $light-taro;
      }

      &:active {
        background-color: #e0f3fe;
        color: $dark-night;
      }

      &:focus-visible {
        background-color: $white;
        outline: 2px solid $primary;
      }
    }
  }
}
