@import "src/components/FormIOForm/styles/foundation/variables";

.flatpickr-calendar {
  box-shadow: 0 3px 14px 2px rgba(0, 0, 0, 0.12) !important;

  &.arrowBottom::after,
  &.arrowBottom::before {
    display: none;
  }

  .flatpickr-months {
    padding: 16px 20px 8px;

    .flatpickr-prev-month {
      order: 2;
    }

    .flatpickr-next-month {
      order: 3;
    }

    // Arrow Prev and Next Icon
    .flatpickr-prev-month,
    .flatpickr-next-month {
      position: unset;
      display: flex;
      border-radius: 50%;
      transition: 0.3s ease background-color;

      &:hover {
        background-color: $light-taro;
        // Back arrow icon
        svg {
          fill: $primary;
        }
      }
    }

    .flatpickr-month {
      order: 1;
      display: flex;

      .flatpickr-current-month {
        width: 100%;
        text-align: left;
        position: unset;
        padding: 0;
        display: flex;
        align-items: center;

        .flatpickr-monthDropdown-months,
        .numInputWrapper .numInput {
          font-size: 16px;
          font-weight: 500;
        }
      }
    }
  }

  .flatpickr-innerContainer {
    .flatpickr-rContainer {
      // Weekdays
      .flatpickr-weekdays {
        height: 40px;

        .flatpickr-weekdaycontainer {
          padding: 0 20px;

          .flatpickr-weekday {
            font-size: 0;
          }

          // Displays initials only
          .flatpickr-weekday::first-letter {
            font-size: 14px;
            font-weight: 400;
            color: $dark-neutral2;
          }
        }
      }

      // Days
      .flatpickr-days .dayContainer {
        padding: 8px 20px 26px;

        .flatpickr-day {
          display: flex;
          align-items: center;
          justify-content: center;
          transition: 0.3s background-color, 0.3s border;

          &.selected {
            background-color: $primary;
            border: none;
          }

          &.today:not(.selected):hover {
            background-color: $light-allspice;
          }

          &:not(.prevMonthDay):not(.selected):not(.nextMonthDay) {
            color: $dark-night;
          }
        }
      }
    }
  }
}
