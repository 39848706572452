.formio-form:not(.formio-read-only) {
  .formio-component-datagrid {
    table.datagrid-table.table-bordered {
      border-radius: 8px;
      border: 1px solid $light-wave;

      td,
      th {
        border: 1px solid $light-wave;
      }

      th {
        color: $dark-neutral;
        font-weight: 600;
        line-height: 21px;
        vertical-align: middle;
        border-bottom: 1px solid $light-allspice;
        box-sizing: border-box;
      }

      td {
        .formio-component-textfield,
        .formio-component-number,
        .formio-component-select,
        .formio-component-textarea {
          margin: 0;

          .form-control,
          .input-group {
            border-bottom: none;
          }
        }

        // Remove Button
        .formio-button-remove-row {
          outline: none;
          width: 36px;
          height: 36px;
          display: flex;
          border-radius: 50%;
          justify-content: center;
          align-items: center;

          // Icon - Remove
          i::before {
            @include font-icon2;

            content: "\ec49";
          }

          &:focus-visible {
            outline: 2px solid $primary;
          }

          &:active {
            background-color: $light-allspice;
            color: $primary;
          }
        }

        &:has(.formio-component-textfield),
        &:has(.formio-component-number),
        &:has(.formio-component-select),
        &:has(.formio-component-textarea),
        &:has(.formio-button-remove-row) {
          padding: 3px 4px;
        }
      }
    }
  }
}
